export default [
  'Отпуск',
  'Без содержания',
  'По болезни',
  'Невыход на работу',
  'Выходные',
  'Дорога',
  'Невыясненные обстоятельства',
  'Учеба',
  'Рабочий день',
]
