export default {
  methods: {
    searchFields(item, component) {
      const fields = this.config.entries[component].map((field) => {
        const {key} = field
        const findTag = this.config.fields[component].find(
          (column) =>
            column.field ===
            this.config.entries[component].find((obj) => obj.place === field.place).key
        )

        const getNameById = (prop) => {
          const dict = `${prop.replace('id_', '')}s`
          const findElem = this.getDicts(dict)?.find((elem) => elem.id === item[prop])
          if (findElem) {
            return findElem.num || findElem.name || findElem.imei
          }
          return ''
        }

        const getDefaultValue = () => {
          if (key in item) {
            return this.$t(`${item[key]}`)
          }
          if (key in item.jdata) {
            return this.$t(`${item.jdata[key]}`)
          }
          return this.$t('undefined')
        }

        if (findTag && findTag.tag === 'select-list') {
          const {items} = this.config.fields[component].find((obj) => obj.field === key)
          if (items) {
            const findItem = items.find(
              (obj) => obj.key === item[key] || obj.key === item.jdata[key]
            )
            if (findItem) {
              return findItem.label || this.$t('false')
            }
            return getDefaultValue()
          }
        } else if (/^id_/.test(key)) {
          return getNameById(key)
        }
        return getDefaultValue()
      })

      return fields
        .filter((field) => !!field)
        .some((field) => field.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
}
