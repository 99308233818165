const mutations = {
  SET_ACTIVE_CONTRACT_ID: (state, {id}) => {
    state.activeContractId = id
  },
}

export default {
  namespaced: true,
  state: {
    activeContractId: null,
  },
  mutations,
}
