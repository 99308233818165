<template>
  <v-flex md12 xs12>
    <v-layout align-center justify-center fill-height>
      <label class="cabinet__label">
        <img
          v-if="getModelInput(field)[field.field]"
          :src="imgSrc"
          @error="onErrorLoadImg"
          class="img__photo"
          alt=""
        />
        <img v-else src="@/assets/no_photo.jpeg" class="img__empty" alt="" />
        <input type="file" @change="changePhoto()" />
      </label>
    </v-layout>
  </v-flex>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import HTTP from '@/api/http'

export default {
  props: {
    component: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    imgSrc: null,
  }),
  computed: {
    getModelInput() {
      return (field) => {
        if (field.is_jdata) {
          return this.options.jdata
        }
        return this.options
      }
    },
    ...mapState(['user_data']),
  },

  mounted() {
    this.getPhoto()
  },
  watch: {
    'options.id': function () {
      this.getPhoto()
    },
  },

  methods: {
    onErrorLoadImg(e) {
      const options = {...this.options}
      if (this.field.is_jdata) {
        options.jdata[this.field.field] = null
      } else {
        options[this.field.field] = null
      }

      this.$emit('update:options', options)
    },
    async getPhoto() {
      const imgSrc = this.getModelInput(this.field)[this.field.field]
      if (!imgSrc) return

      const {data: blob} = await HTTP.get(`photo/${imgSrc}`, {
        responseType: 'blob',
      })
      const reader = new FileReader()
      reader.onloadend = () => {
        this.imgSrc = reader.result
      }
      reader.readAsDataURL(blob)
    },
    async changePhoto() {
      const input = document.querySelector('input[type=file]')
      const file = input.files[0]

      const data = new FormData()
      data.append('files', file)

      const {data: imgIds} = await HTTP.put(`photo`, data)
      const [imgId] = imgIds

      const options = {...this.options}
      if (this.field.is_jdata) {
        options.jdata[this.field.field] = imgId
      } else {
        options[this.field.field] = imgId
      }

      this.$emit('update:options', options)
      this.getPhoto()
    },
  },
}
</script>

<style scoped lang="sass">
.img__empty
  border-radius: 50%
  height: 200px
  width: 200px

.img__photo
  min-height: 50px
  min-width: 50px
  max-height: 244px
  max-width: 244px
</style>
