<template>
  <v-layout wrap>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'Выполнено, руб. без НДС' }}</p>
        <span class="text-center">{{
          formatFloatValue(counterpartieInfoData?.price_done ?? 0)
        }}</span>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'Оплачено, руб. без НДС' }}</p>
        <span class="text-center">{{
          formatFloatValue(counterpartieInfoData?.price_paid ?? 0)
        }}</span>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'К оплате по договорам, руб. без НДС' }}</p>
        <span class="text-center">{{
          formatFloatValue(counterpartieInfoData?.price_pay_con ?? 0)
        }}</span>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'К оплате за исполненное, руб. без НДС' }}</p>
        <span class="text-center">{{
          formatFloatValue(counterpartieInfoData?.price_fulfilled ?? 0)
        }}</span>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="master__paragraph master__title mb-2">{{ 'Объекты' }}</p>
        <v-autocomplete
          v-model="geometry.value"
          class="input__main px-2 pt-0 mt-0"
          :items="geometry.items"
          item-value="id"
          item-text="name"
          hide-details
          height="27"
          color="main_color"
          item-color="main_color"
        >
        </v-autocomplete>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="master__paragraph master__title mb-2">{{ 'Дата' }}</p>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{on}">
            <v-text-field
              v-on="on"
              solo
              single-line
              flat
              class="input__options"
              placeholder="Дата"
              v-model="date_oper"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="date_oper"
            no-title
            scrollable
            first-day-of-week="1"
            locale="ru-Ru"
            :color="$vuetify.theme.main_color"
          >
          </v-date-picker>
        </v-menu>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import Api from '@/api/api'
import {getToday} from '@/utils/date.js'
import {hasErrorResponse} from '@/utils/api.js'

export default {
  props: {
    component: {
      type: String,
    },
    options: {
      type: Object,
      default: () => ({
        jdata: {},
      }),
    },
  },

  data: () => ({
    counterpartieInfoData: null,
    date_oper: null,
    geometry: {
      items: [],
      value: null,
    },
  }),

  mounted() {
    this.setDefaultDateOper()
    this.getGeometryList()
  },
  watch: {
    $route() {
      this.setDefaultDateOper()
      this.getGeometryList()
      this.getCounterpartieInfoData()
    },
    date_oper() {
      this.getCounterpartieInfoData()
    },
    'geometry.value': function () {
      this.getCounterpartieInfoData()
    },
  },

  methods: {
    setDefaultDateOper() {
      this.date_oper = getToday()
    },
    async getGeometryList() {
      const {id} = this.options
      const {data} = await Api.counterparties.post.geometryList({id_counterpartie: id})

      if (!hasErrorResponse(data)) {
        console.log('data :>> ', data)
        this.geometry.items = data
      }
    },
    formatFloatValue(value) {
      return convertNumberToLocaleString(value)
    },
    async getCounterpartieInfoData() {
      const {id} = this.options
      const {data} = await Api.counterparties.post.info({
        id_counterpartie: id,
        date_oper: this.date_oper,
        id_geometry: this.geometry.value,
      })

      if (!hasErrorResponse(data)) {
        this.counterpartieInfoData = data
      }
    },
  },
}
</script>
