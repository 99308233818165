<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="dictionaryModule.activeModule.label"></dict-title>

    <div class="d-flex overflow-hidden pt-4 fill-height">
      <v-card class="d-flex flex-column elevation-2 pt-6 flex-grow-1">
        <v-layout class="pt-3 px-3 flex-grow-0" justify-space-between align-center wrap>
          <v-flex shrink>
            <v-layout class="my-0" align-center>
              <v-menu offset-y :close-on-content-click="false" ref="calendar">
                <template v-slot:activator="{on}">
                  <v-text-field
                    v-on="on"
                    solo
                    single-line
                    flat
                    hide-details
                    class="input__options"
                    readonly
                    :value="formatDate(date, 'YYYY-MM-DD')"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  locale="ru-Ru"
                  color="main_color"
                  :events="table.events"
                  event-color="primary"
                  @change="$refs.calendar.save(date)"
                >
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout justify-end align-center>
              <v-dialog
                v-model="isShowAddElement"
                width="480"
                v-if="dictionaryModule.activeModule.items.isadd"
              >
                <template v-slot:activator="{on}">
                  <v-btn color="main_color" depressed small dark v-on="on">
                    {{ $t('add') }}
                    <v-icon right>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <add-table-element-card
                  v-if="isShowAddElement"
                  :component="dictionaryModule.activeModule.items.type_rtable"
                  @save="putTablesElement"
                ></add-table-element-card>
              </v-dialog>
              <v-btn icon class="ma-2" @click="prevDay()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon class="ma-2" @click="nextDay()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-card class="table__main elevation-0 overflow-y-auto flex-grow-1">
          <v-data-table
            :headers="filteredColumns"
            :items="table.models"
            hide-default-footer
            fixed-header
            height="auto"
            :items-per-page="-1"
          >
            <template v-for="tableSlot in table.columns" #[`item.${tableSlot.value}`]="props">
              <table-slot
                :key="tableSlot.value"
                :table-slot="tableSlot"
                :props="props"
                @save="saveTableColumnValue"
              ></table-slot>
            </template>

            <template #[`item.actions`]="props">
              <table-actions-slot
                :props="props"
                @delete="deleteTableElement"
                @update="updateTableElement"
              ></table-actions-slot>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import HTTP from '@/api/http'
import dayjs from 'dayjs'
import {getDateRangeOfDay} from '@/utils/date.js'
import {formatDate} from '@/utils/index.js'

import dictTitle from '@/components/custom/dicts/title'
import tableSlot from '@/components/tables/tableSlot'
import addTableElementCard from '@/components/tables/addTableElementCard'
import tableActionsSlot from '@/components/tables/tableActionsSlot'

export default {
  components: {
    dictTitle,
    tableSlot,
    addTableElementCard,
    tableActionsSlot,
  },

  props: {
    component: {
      type: String,
    },
  },

  data: () => ({
    date: null,
    table: {
      columns: [],
      models: [],
    },
    isShowAddElement: false,
  }),

  computed: {
    editableTableSlots() {
      return this.table.columns.filter((tableColumn) => !tableColumn.readonly)
    },

    filteredColumns() {
      return this.table.columns
        .filter((column) => column.visible)
        .concat({text: '', value: 'actions', sortable: false})
    },

    ...mapState(['dictionaryModule']),
    ...mapGetters(['config', 'getDicts']),
  },

  watch: {
    $route() {
      this.setDictionaryActiveModule()
      this.getTableReport()
    },
    date() {
      this.getTableReport()
    },
  },

  beforeMount() {
    this.setDictionaryActiveModule()
    this.setDefaultDate()
  },

  methods: {
    setDictionaryActiveModule() {
      const {field} = this.$route.query
      const module = this.config.fields[this.component].find(
        (dictionaryField) => dictionaryField.field === field
      )

      if (module) {
        this.$store.commit('dictionaryModule/SET_ACTIVE_MODULE', module)
      }
    },

    setDefaultDate() {
      if (!this.date) {
        this.date = dayjs().format('YYYY-MM-DD')
      }
    },

    prevDay() {
      this.date = dayjs(this.date).subtract(1, 'days').format('YYYY-MM-DD')
    },

    nextDay() {
      this.date = dayjs(this.date).add(1, 'days').format('YYYY-MM-DD')
    },

    getDateRange() {
      return [
        dayjs(this.date).format('YYYY-MM-DD '),
        dayjs(this.date).add(1, 'days').format('YYYY-MM-DD'),
      ]
    },

    async getTableReport() {
      const {field: request, items} = this.dictionaryModule.activeModule
      const [date_from, date_to] = getDateRangeOfDay(this.date)
      const id = `id_${this.component.slice(0, -1)}`
      const config = {date_from, date_to, [id]: this.$route.params[this.component], ...items}

      const {data} = await this.$api.tables.post(request, config)
      this.table = data
    },

    async saveTableColumnValue(column) {
      const {
        items: {type_rtable: request},
      } = this.dictionaryModule.activeModule
      const [date_from, date_to] = getDateRangeOfDay(this.date)
      const config = {date_from, date_to, ...column}

      await HTTP.put(request, config)
    },

    async putTablesElement(element) {
      const {
        items: {type_rtable: request},
      } = this.dictionaryModule.activeModule
      const id = `id_${this.component.slice(0, -1)}`
      await HTTP.put(request, {...element, [id]: this.$route.params[this.component]})

      this.getTableReport()
      this.isShowAddElement = false
    },

    updateTableElement(element) {
      this.putTablesElement(element)
    },

    async deleteTableElement(element) {
      const {
        items: {type_rtable: dict},
      } = this.dictionaryModule.activeModule
      await this.$store.dispatch('deleteItem', {element, dict})
      this.getTableReport()
    },

    formatDate,
  },
}
</script>
