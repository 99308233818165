<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="$t('history.title')">
      <v-btn dark icon @click="getReport($route)">
        <v-icon>mdi-update</v-icon>
      </v-btn>
    </dict-title>

    <div class="d-flex overflow-hidden pt-4 fill-height">
      <v-card class="d-flex flex-column elevation-2 pt-4 flex-grow-1">
        <v-layout class="pt-3 px-3 flex-grow-0" justify-space-between align-center wrap>
          <v-flex>
            <v-layout class="my-0" align-center>
              <date-picker
                v-model="date_picker.time"
                class="px-3 black--text input__calendar"
                type="datetime"
                range
                value-type="format"
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Выберите период"
                confirm
                confirm-text="Применить"
                range-separator=" - "
                :clearable="false"
                :shortcuts="shortcuts"
                @confirm="changeTimeRange()"
              >
                <template v-slot:icon-calendar>
                  <v-icon v-text="'$calendar'" size="17"></v-icon>
                </template>
              </date-picker>
            </v-layout>
          </v-flex>
          <v-flex md5>
            <v-layout justify-end>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn class="btn__reports" @click="togglePage()" v-on="on">
                    <v-icon v-text="'$graph'"></v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('rtables.graph') }}</span>
              </v-tooltip>

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn class="btn__reports" :href="getReportFile()" v-on="on">
                    <v-icon v-text="'$save_report'"></v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('rtables.save_report') }}</span>
              </v-tooltip> -->

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    class="btn__reports"
                    v-on="on"
                    >
                    <v-icon v-text="'$total'"></v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('rtables.total') }}</span>
              </v-tooltip> -->
            </v-layout>
          </v-flex>
        </v-layout>

        <div class="px-3">
          <v-text-field class="pt-0" type="search" v-model="search" color="main_color">
            <template v-slot:append>
              <v-icon v-text="'$search'" size="14" class="mt-2"></v-icon>
            </template>
          </v-text-field>
        </div>

        <v-card ref="table" class="table__main elevation-0 overflow-y-auto flex-grow-1">
          <custom-table v-if="page === 'table'" :table="table" :search="search"></custom-table>
          <line-chart
            v-if="page === 'graph'"
            class="fill-height"
            :chart-data="chart.data"
          ></line-chart>
        </v-card>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import {mapState, mapGetters} from 'vuex'
import HTTP from '@/api/http'
import reports from '@/mixins/reports'
import lineChart from '@/components/secondComponents/LineChart'

import customTable from '@/components/custom/custom-table'
import dictTitle from '@/components/custom/dicts/title'

import moment from 'moment'

export default {
  components: {
    lineChart,
    DatePicker,
    customTable,
    dictTitle,
  },

  mixins: [reports],

  props: {
    component: {
      type: String,
    },
  },

  data: () => ({
    chart: {
      data: {
        labels: [],
        datasets: [],
      },
      options: {},
    },
  }),

  computed: {
    ...mapState(['auth']),
    ...mapGetters(['getDicts', 'config']),
  },

  beforeRouteUpdate(to, from, next) {
    this.getReport(to)
    next()
  },

  methods: {
    async getReport(route) {
      const [from, to] = this.date_picker.time
      this.table.headers = []
      this.table.items = []
      this.loading = true

      const {field} = this.$route.query
      const [request = 'reports'] = field.split('_')
      const options = this.getOptsReport(route)
      const {data} = await HTTP.post(request, options)

      if (data?.columns) {
        const header = [
          {
            value: 'number',
            sortable: false,
            text: '№',
          },
        ]

        this.table.headers = header
          .concat(data.columns.filter((item) => item.visible).sort((a, b) => a.forder - b.forder))
          .map((item) => ({
            ...item,
            align: 'left',
          }))

        if (!data.models) {
          this.loading = false
          return
        }
        const filterItog = data.models.filter((item) => item._is_itog)

        this.table.names = data.columns
          .filter((item) => item.visible)
          .sort((a, b) => a.forder - b.forder)
          .map((item) => item.value)
        this.table.items = data.models
          .filter((item) => !item._is_itog)
          .map((item, index) => ({
            ...item,
            index: index + 1,
          }))
          .concat(filterItog)
      }

      this.loading = false
    },
    getAdditionalOptsReport() {
      if (/^\/reports/.test(this.$route.path)) {
        const {rtables: id_rtable} = this.$route.params
        const {show_itog, type_dict, type_group, type_rtable} =
          this.getDicts('rtables').find((rtableItem) => rtableItem.id === id_rtable) ?? {}
        return {
          show_itog,
          type_dict,
          type_group,
          type_rtable,
        }
      }

      const {field} = this.$route.query
      const config_opts =
        this.config.fields[this.component].find((el) => el.field === field)?.items ?? {}
      return config_opts
    },
    getOptsReport(route) {
      const [date_from, date_to] = this.date_picker.time
      const findElem = this.getDicts(this.component).find(
        (elem) => elem.id === route.params[this.component || 'sensors']
      )
      const id = `id_${this.component.slice(0, -1)}`

      const obj = {
        date_from,
        date_to,
        [id]: findElem.id,
        ...this.getAdditionalOptsReport(),
        // ...config_opts
      }
      return obj
    },
    getReportTitle() {
      const id = this.$route.params[this.component]
      const {name} = this.getDicts(this.component).find((elem) => elem.id === id)
      const table = this.getDicts('rtables').find((item) => item.id === this.$route.params.rtables)

      return `${name} - ${table.name}`
    },
    getReportFile() {
      let href = `http://${location.host}/downloads/report.xlsx?`

      let id
      if (this.component === 'tsensors') {
        id = 'id_tsensor'
      } else {
        id = `id_${this.component.slice(0, -1)}`
      }

      Object.entries(this.getOptsReport(this.$route)).forEach((arr) => {
        if (['date_from', 'date_to', id, 'type_report'].includes(arr[0])) {
          const param = arr.join('=')
          href += `&${param}`
        }
      })

      return href
    },
  },
}
</script>
