<template>
  <v-flex>
    <v-layout justify-center>
      <v-flex md8 xs12>
        <dict-title :title="$t('component.cabinet')">
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{on}">
              <v-btn icon text v-on="on">
                <v-icon v-text="`$vuetify.icons.lang`" color="white"></v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-layout column>
                <v-btn class="ma-0" @click="changeLang('ru')">RUS</v-btn>
                <v-btn class="ma-0" @click="changeLang('en_en')">ENG</v-btn>
              </v-layout>
            </v-card>
          </v-menu>
          <!-- <v-menu>
            <template v-slot:activator="{on}">
              <v-btn icon text v-on="on">
                <v-icon v-text="`$vuetify.icons.palitra`" color="white"></v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-layout column>
                <v-btn
                  v-for="(theme, i) in getThemeColors"
                  :key="i"
                  icon
                  text
                  small
                  @click="setThemeColor(theme.color_name)"
                  class="mx-auto my-1"
                  :style="`background: ${theme.main_color}`"
                >
                </v-btn>
              </v-layout>
            </v-card>
          </v-menu> -->
        </dict-title>

        <!-- Профиль -->
        <v-card style="padding-top: 57px; margin-top: 14px; min-height: 90vh">
          <v-tabs background-color="#F0EEEE" color="main_color" style="width: 100%">
            <v-tabs-slider color="main_color"></v-tabs-slider>
            <v-tab
              v-for="tab in getCabinetTabs"
              :key="tab"
              style="font-size: 11px; color: #a0a4a7"
              ripple
            >
              {{ tab }}
            </v-tab>

            <v-tab-item :transition="false" :reverse-transition="false">
              <v-layout column class="px-3 pt-10">
                <v-col md="6">
                  <options-settings component="modules" :options="module_data"></options-settings>

                  <v-layout justify-center>
                    <!-- <v-btn class="btn__save px-4 mx-1" text @click="addAccess()">
                      <span>{{ $t('cabinet.createModule') }}</span>
                    </v-btn> -->
                    <v-btn class="btn__save px-4 mx-1" text @click="updateModule()">
                      <span>{{ $t('save') }}</span>
                    </v-btn>
                    <!-- <v-btn class="btn__save px-4 mx-1" text @click="deleteModule()">
                      <span>{{ $t('remove') }}</span>
                    </v-btn> -->
                  </v-layout>
                </v-col>
              </v-layout>
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false">
              <v-layout column class="px-3 pt-10">
                <v-col md="6">
                  <options-settings component="users" :options="user"></options-settings>

                  <v-layout justify-center>
                    <v-btn class="btn__save px-4" text @click="updateUser()">
                      <span>{{ $t('save') }}</span>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-layout>
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false">
              <v-layout column class="px-3 pt-10">
                <v-col md="6">
                  <options-settings component="companys" :options="access"></options-settings>

                  <v-layout justify-center>
                    <v-btn class="btn__save px-4" text @click="updateAccess()">
                      <span>{{ $t('save') }}</span>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>

    <v-snackbar top right v-model="notify.value" :timeout="5000" class="pt-10">
      <v-layout column class="px-2">
        <h2 class="mb-3 mx-auto">{{ notify.text }}</h2>
        <v-flex>
          <v-layout justify-center>
            <v-btn
              color="main_color"
              text
              depressed
              @click="notify.value = false"
              class="mx-0 my-0 px-2"
            >
              {{ $t('close') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-snackbar>
  </v-flex>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import HTTP from '@/api/http'
import auth from '@/mixins/auth'

import dictTitle from '@/components/custom/dicts/title'
import optionsSettings from '@/components/custom/options/settings'

import {mask} from 'vue-the-mask'

export default {
  directives: {
    mask,
  },

  components: {
    dictTitle,
    optionsSettings,
  },

  mixins: [auth],

  data() {
    return {
      module_data: {
        jdata: {},
      },
      user: {
        jdata: {},
      },
      access: {
        jdata: {},
      },
      employee: {},
      notify: {
        value: false,
        text: null,
      },
    }
  },
  computed: {
    getCabinetTabs() {
      return [this.$t('cabinet.module'), this.$t('cabinet.user'), this.$t('cabinet.company')]
    },
    getTypesModule() {
      return ['eco', 'smis', 'agro', 'hmg', 'acs'].map((mod) => ({
        key: this.$t(`menu.${mod}`),
        value: mod,
      }))
    },
    ...mapState(['auth']),
    ...mapGetters(['getThemeColors', 'getDicts']),
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.module_data = {...this.auth}
      this.getUserData()
      this.getAccessData()
    },
    async getUserData() {
      const {data} = await HTTP.post('user')
      this.user = data
    },
    async getAccessData() {
      const {data} = await HTTP.post('company')
      this.access = data
    },
    async updateModule() {
      const {data} = await HTTP.put('modules', this.module_data)

      if (data.id) {
        this.$store.commit('PUT_ITEM', {
          ...this.module_data,
          jdata: {
            ...this.module_data.jdata,
            component: 'modules',
          },
        })
      }

      await this.$store.dispatch('auth/getModule')
    },
    async deleteModule() {
      await HTTP.delete('module')
      await HTTP.get('access')
    },
    async updateUser() {
      await HTTP.put('user', this.user)
      await this.$store.dispatch('auth/getUser')
    },
    async addAccess() {
      const options = {
        ...this.module_data,
      }
      options.id_access = this.auth.id_access
      await HTTP.post('reg', options)

      this.$store.dispatch('getItems', 'modules')
    },
    async updateAccess() {
      const {data} = await HTTP.put('company', this.access)

      if (data.id) {
        this.$store.commit('PUT_ITEM', {
          ...this.access,
          jdata: {
            ...this.access.jdata,
            component: 'companys',
          },
        })
      }
    },
    async changeLang(locale) {
      this.$i18n.locale = locale
      this.user.lang = locale
      this.updateUser()
    },
    async setThemeColor(color) {
      this.module_data.jdata.color = color
      await this.updateModule()
      this.$store.dispatch('updateThemeColor', {
        $vuetify: this.$vuetify,
      })
    },
  },
}
</script>
