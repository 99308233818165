<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="$t('calendar.title')"></dict-title>

    <div class="d-flex overflow-hidden pt-4 fill-height">
      <v-card class="d-flex flex-column elevation-2 pt-4 flex-grow-1">
        <v-layout class="pt-3 pb-1 px-3 flex-grow-1 flex-column overflow-scroll fill-height">
          <v-layout class="flex-grow-1" justify-space-between align-center>
            <v-layout class="my-2 flex-grow-0">
              <v-select
                :items="getCalendarTypes"
                v-model="calendarProps.type"
                label=""
                outlined
                hide-details
                dense
              ></v-select>
            </v-layout>
            <v-layout justify-end>
              <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-layout>
          </v-layout>

          <v-calendar
            class="overflow-scroll"
            ref="calendar"
            v-model="calendarValue"
            v-bind="calendarProps"
            @click:date="setCalendarType('day')"
            @click:more="
              ({date}) => {
                this.calendarValue = date
                setCalendarType('day')
              }
            "
            @click:event="showEvent"
          ></v-calendar>

          <v-menu
            v-model="isShowEvent"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            :max-width="320"
          >
            <EventCard :data="selectedEvent"></EventCard>
          </v-menu>
        </v-layout>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import {mapGetters} from 'vuex'
import HTTP from '@/api/http'
import reportsRequests from '@/mixins/reportsRequests'

import dictTitle from '@/components/custom/dicts/title'
import EventCard from '@/components/calendar/EventCard.vue'

const calendarTypes = ['month', 'week', 'day']

export default {
  components: {
    dictTitle,
    EventCard,
  },

  mixins: [reportsRequests],

  props: {
    component: {
      type: String,
    },
  },

  data: () => ({
    calendarProps: {
      locale: 'ru',
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      color: 'main_color',
      type: 'month',
      events: [],
    },
    calendarValue: null,
    calendarEventColors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1',
    ],

    isShowEvent: false,
    selectedEvent: null,
    selectedElement: null,
  }),

  computed: {
    getCalendarTypes() {
      return calendarTypes.map((calendarType) => ({
        text: this.$t(`utils.date.${calendarType}`),
        value: calendarType,
      }))
    },

    ...mapGetters(['getDicts', 'config']),
  },

  beforeMount() {
    this.getCalendarData()
  },

  watch: {
    $route() {
      this.getCalendarData()
    },
  },

  methods: {
    async getCalendarData() {
      const {request, ...options} = this.getRequestOptions({tagName: 'top-btn-calendar'})
      const {data} = await HTTP.post(request, options)

      if (data) {
        this.calendarProps = {
          ...this.calendarProps,
          ...data,
          events: data.events?.map((event, index) => {
            const colorIndex = index % this.calendarEventColors.length
            return {
              ...event,
              color: this.calendarEventColors[colorIndex],
            }
          }),
        }
      }
    },

    setCalendarType(type) {
      this.calendarProps.type = type
    },

    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() =>
          requestAnimationFrame(() => {
            this.isShowEvent = true
          })
        )
      }

      if (this.isShowEvent) {
        this.isShowEvent = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },
}
</script>
