<template>
  <v-card>
    <v-card-title>
      {{ data.name }}
    </v-card-title>
    <v-card-text>
      <v-layout flex-column>
        <span>{{ $t('calendar.eventCard.start') }}: {{ data.start }}</span>
        <span>{{ $t('calendar.eventCard.end') }}: {{ data.end }}</span>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
