<template>
  <v-card>
    <v-card-title class="text-h5">{{ title }}</v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <options-settings
        :options="options"
        :component="component"
        :config-list="configList"
      ></options-settings>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="btn__save px-4" text @click="saveItem">
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import optionsSettings from '@/components/custom/options/settings'
import setListsOfElems from '@/mixins/setListsOfElems'

export default {
  components: {
    optionsSettings,
  },

  mixins: [setListsOfElems],

  props: {
    component: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default() {
        return this.$t('add')
      },
    },
    initOptions: {
      type: Object,
      default: () => ({}),
    },
    configList: {
      type: String,
      default: 'master',
    },
  },

  data: () => ({
    options: {
      jdata: {},
    },
  }),

  beforeMount() {
    this.options = {...this.options, ...this.initOptions}
  },

  methods: {
    saveItem() {
      this.$emit('save', this.options)
    },
  },
}
</script>
