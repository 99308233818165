<template>
  <v-layout wrap>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'Выполнено, руб. без НДС' }}</p>
        <span class="text-center">{{ formatFloatValue(contractInfoData?.price_done ?? 0) }}</span>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'Оплачено, руб. без НДС' }}</p>
        <span class="text-center">{{ formatFloatValue(contractInfoData?.price_paid ?? 0) }}</span>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'К оплате по договору, руб. без НДС' }}</p>
        <span class="text-center">{{
          formatFloatValue(contractInfoData?.price_pay_con ?? 0)
        }}</span>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'К оплате за исполненное, руб. без НДС' }}</p>
        <span class="text-center">{{
          formatFloatValue(contractInfoData?.price_fulfilled ?? 0)
        }}</span>
      </v-layout>
    </v-flex>
    <v-flex md3 sm6 xs12>
      <v-layout column align-center class="options__title black--text">
        <p class="mb-0">{{ 'Дата' }}</p>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{on}">
            <v-text-field
              v-on="on"
              solo
              single-line
              flat
              class="input__options"
              placeholder="Дата"
              v-model="date_oper"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="date_oper"
            no-title
            scrollable
            first-day-of-week="1"
            locale="ru-Ru"
            :color="$vuetify.theme.main_color"
          >
          </v-date-picker>
        </v-menu>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import Api from '@/api/api'
import {getToday} from '@/utils/date.js'
import {hasErrorResponse} from '@/utils/api.js'
import {convertNumberToLocaleString} from '@/utils/number'

export default {
  props: {
    component: {
      type: String,
    },
    options: {
      type: Object,
      default: () => ({
        jdata: {},
      }),
    },
  },

  data: () => ({
    contractInfoData: null,
    date_oper: null,
  }),

  mounted() {
    this.setDefaultDateOper()
  },
  watch: {
    $route() {
      this.setDefaultDateOper()
      this.getContractInfoData()
    },
    date_oper() {
      this.getContractInfoData()
    },
  },

  methods: {
    setDefaultDateOper() {
      this.date_oper = getToday()
    },
    formatFloatValue(value) {
      return convertNumberToLocaleString(value)
    },
    async getContractInfoData() {
      const {id} = this.options
      const {data} = await Api.contracts.post.info({id_contract: id, date_oper: this.date_oper})

      if (!hasErrorResponse(data)) {
        this.contractInfoData = data
      }
    },
  },
}
</script>
