<template>
  <v-flex md4 sm12 xs12>
    <v-layout column>
      <dict-title :title="$t('master.title')"></dict-title>

      <v-layout class="pt-8" style="height: calc(100vh - 110px)">
        <v-flex>
          <v-card class="py-3 pt-3 mt-n4 elevation-0">
            <v-slide-x-transition mode="out-in">
              <v-layout style="padding: 50px 24px 100px">
                <options-settings
                  :options="options"
                  :component="component"
                  config-list="master"
                ></options-settings>
              </v-layout>
            </v-slide-x-transition>

            <v-layout justify-center>
              <v-btn class="btn__save px-4" text @click="putItem">
                <span>{{ $t('save') }}</span>
              </v-btn>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>

    <v-snackbar top v-model="notify.show" :timeout="2000">
      <v-layout column class="px-2">
        <h2 class="mb-3 mx-auto white--text">{{ notify.message }}</h2>
        <v-flex>
          <v-layout justify-center>
            <v-btn
              color="main_color"
              text
              depressed
              @click="notify.show = false"
              class="mx-0 my-0 px-2"
            >
              {{ $t('close') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-snackbar>
  </v-flex>
</template>

<script>
import HTTP from '@/api/http'
import setListsOfElems from '@/mixins/setListsOfElems'
import dictTitle from '@/components/custom/dicts/title'
import optionsSettings from '@/components/custom/options/settings'

import {mask} from 'vue-the-mask'

export default {
  directives: {
    mask,
  },

  components: {
    dictTitle,
    optionsSettings,
  },

  mixins: [setListsOfElems],

  props: {
    component: {
      type: String,
      default: 'default',
    },
    secondComponent: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      options: {
        jdata: {},
      },
      time: {
        from: null,
        to: null,
      },
      menu: {
        from: false,
        to: false,
      },
      notify: {
        show: false,
        message: '',
      },
    }
  },

  created() {
    this.setElems()
  },

  methods: {
    putItem() {
      const obj = {
        ...this.options,
      }

      HTTP.put(`${this.component}`, obj)
        .then((response) => {
          const {data} = response
          const elem = {
            ...obj,
            id: data.id,
            ...data,
            jdata: {
              ...obj.jdata,
              component: this.component,
            },
          }

          this.$store.commit('PUT_ITEM', elem)
          this.$router.push(`/dictionary/${this.component}/${this.elem.parent}_${data.id}`)
        })
        .catch((err) => {
          console.log(err)
          this.showNotify('Элемент не создан')
        })
    },
    showNotify(msg) {
      console.log(msg)
      this.notify.message = msg
    },
  },
}
</script>

<style scoped lang="sass">
.v-list
  padding: 0

  .v-list__tile
    font-size: 12px
    height: 34px
    padding: 0 8px
</style>
