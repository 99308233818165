// Для таблиц

import moment from 'moment'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      search: '',
      loading: false,
      table: {
        tsensors: [],
        headers: [],
        items: [],
        names: [],
        height: 'auto',
      },
      date_picker: {
        time: [],
      },
      shortcuts: [],
      page: 'table',
    }
  },
  computed: {
    ...mapGetters(['getDicts', 'interval']),
  },

  watch: {
    'date_picker.time': async function (value) {
      await this.changeTimeRange()
      this.$store.commit('UPDATE_INTERVAL', value)
    },
  },
  created() {
    this.date_picker.time = this.interval || [
      moment()
        // .subtract(30, 'days')
        .format('YYYY-MM-DD 00:00:00'),
      moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
    ]
    this.setShorcuts()
  },
  mounted() {
    this.$nextTick(() => {
      this.table.height = this.getTableHeight()
    })
  },
  updated() {
    this.table.height = this.getTableHeight()
  },

  methods: {
    getTableHeight() {
      const {table} = this.$refs
      if (!table) return 'auto'
      const {height} = getComputedStyle(table.$el)
      // console.log(height)
      return height || 'auto'
    },
    togglePage() {
      this.page = this.page === 'table' ? 'graph' : 'table'
    },
    async changeTimeRange() {
      await this.getReport(this.$route)
    },
    setShorcuts() {
      this.shortcuts = [
        {
          text: 'Сегодня',
          onClick: () => this.getRange(),
        },
        {
          text: 'Вчера',
          onClick: () =>
            this.getRange({
              from: {
                days: 1,
              },
              to: {
                days: 0,
              },
            }),
        },
        {
          text: 'Месяц',
          onClick: () =>
            this.getRange({
              from: {
                months: 1,
              },
            }),
        },
        {
          text: 'Квартал',
          onClick: () =>
            this.getRange({
              from: {
                months: 3,
              },
            }),
        },
        {
          text: 'Год',
          onClick: () =>
            this.getRange({
              from: {
                years: 1,
              },
            }),
        },
      ]
    },
    getRange({
      from = {},
      to = {
        days: 1,
      },
    } = {}) {
      const options = {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
      const [date_from, date_to] = [
        moment(options).subtract(from).toDate(),
        moment(options).add(to).toDate(),
      ]
      return [date_from, date_to]
    },
  },
}
