import axios from 'axios'
import keycloak from '@/utils/keycloak'
import store from '@/store/store'

const addContractIdToData = (req) => {
  const id_contract = store.state.contracts.activeContractId
  if (id_contract) {
    req.id_contract = id_contract
  }
}

const HTTP = axios.create({
  baseURL: '/api/',
  transformRequest: [
    (req) => {
      if (req) {
        addContractIdToData(req)
      }
      return req
    },
    ...axios.defaults.transformRequest,
  ],
})

HTTP.interceptors.request.use((config) => {
  const {url} = config
  const keycloakToken = keycloak.token
  config.headers.token = keycloakToken
  if (
    ['key_redis', 'set_access', 'logout', 'upload', 'login', 'reg', 'pub', 'jpeg'].includes(
      url.replace(/^(\w+).*/, '$1')
    )
  ) {
    config.baseURL = '/'
  }
  return config
})

HTTP.interceptors.response.use(
  (response) => {
    if (response?.data?.status === 'error') {
      console.error(response.request.responseURL, response.data)
    }
    return response
  },
  (err) => {
    if (err.response.status === 401) {
      keycloak.logout()
    }
    return Promise.reject(err)
  }
)

export default HTTP
