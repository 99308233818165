<template>
  <v-select
    dense
    hide-details
    style="width: 200px"
    :items="typeWorkdaysList"
    v-model="props.item[tableSlot.value]"
    @input="$emit('save', props.item)"
    v-if="tableSlot.value === 'type_workdays' && !tableSlot.readonly"
  ></v-select>

  <v-edit-dialog
    v-else-if="!tableSlot.readonly"
    :return-value.sync="props.item[tableSlot.value]"
    @save="$emit('save', props.item)"
  >
    {{
      tableSlot.value.startsWith('id_')
        ? getNameById(props.item[tableSlot.value])
        : props.item[tableSlot.value]
    }}
    <template #input>
      <v-text-field v-model="props.item[tableSlot.value]" single-line></v-text-field>
    </template>
  </v-edit-dialog>

  <td class="text-start" v-else>
    {{
      tableSlot.value.startsWith('id_')
        ? getNameById(props.item[tableSlot.value])
        : props.item[tableSlot.value]
    }}
  </td>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import typeWorkdaysList from '@/mock/tables/type_workdays.js'

export default {
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
    tableSlot: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    typeWorkdaysList() {
      return typeWorkdaysList
    },

    ...mapState(['dictionaryModule']),
    ...mapGetters(['getDicts']),
  },

  methods: {
    getNameById(id, prop = 'name') {
      const {type_dict} = this.dictionaryModule.activeModule.items
      const element = this.getDicts(type_dict).find((dictElement) => dictElement.id === id)
      return element?.[prop] ?? ''
    },
  },
}
</script>
