<template>
  <v-menu
    transition="slide-y-transition"
    color="transparent"
    :nudge-top="-36"
    :nudge-right="0"
    :close-on-content-click="false"
    v-model="menus.showNotifies"
    class="elevation-0"
  >
    <template v-slot:activator="{on}">
      <v-btn v-on="on" small icon text max-height="32" max-width="32" class="my-0 mx-2">
        <v-icon>mdi-bell</v-icon>
      </v-btn>
    </template>
    <v-card style="width: 320px; height: 292px" class="transparent overflow-y-scroll elevation-0">
      <v-card class="elevation-0 pa-2 transparent" style="background: rgba(255, 255, 255, 0.2)">
        <v-card
          style="border-radius: 6px; margin-bottom: 6px"
          v-for="notify in globalNotifies"
          :key="notify.id"
          @click="setAccess(notify)"
          class="pb-2 pt-0 elevation-1 notify"
        >
          <v-flex xs12>
            <v-layout
              justify-space-between
              class="mb-1 px-2 py-1"
              style="border-radius: 5px 5px 0 0; background: #f4f4f4"
            >
              <v-layout align-center class="notify__title">
                <img src="@/assets/vue.svg" style="height: 16px; width: 16px" alt="" />
                <span class="ml-1">{{ getModule(notify.id_access).name }}</span>
              </v-layout>
              <v-layout align-center justify-end class="notify__datepoint">
                <span>{{ getDatepoint(notify) }}</span>
              </v-layout>
            </v-layout>
            <div class="px-2 mt-2 mb-1">
              <p class="mb-0 mt-0 notify__subject">{{ notify.subject }}</p>
              <p class="mb-0 notify__mess">{{ notify.message }}</p>
            </div>
          </v-flex>
        </v-card>
      </v-card>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment'
import {mapState, mapGetters} from 'vuex'
import pSocket from '@/mixins/pSocket'
import HTTP from '@/api/http'
import auth from '@/mixins/auth'
import {ROUTER_MAIN} from '@/utils/constants'

export default {
  mixins: [pSocket, auth],

  mqtt: {
    PUSH(data, topic) {
      this.onMessage(data)
    },
  },

  data() {
    return {
      menus: {
        showNotifies: false,
      },
      globalNotifies: [],
    }
  },
  computed: {
    getDatepoint() {
      return (notify) => {
        return moment.parseZone(notify.datepoint).fromNow()
      }
    },
    ...mapState(['auth']),
    ...mapGetters(['getDicts']),
  },

  mounted() {
    // this.$mqtt.subscribe(`PUSH`)
    moment.locale('ru')
  },

  methods: {
    onMessage(json) {
      const {data} = json
      const parseData = JSON.parse(data)
      this.addNotify(parseData)
    },
    addNotify(data) {
      this.globalNotifies.push(data)
    },
    removeGlobalNotify(notify) {
      this.globalNotifies = this.globalNotifies.filter(
        (notification) => notification.datepoint !== notify.datepoint
      )
    },
    getModule(id_access) {
      return this.getDicts('modules').find((mod) => mod.id === id_access) || {}
    },
    async setAccess({id_access}) {
      this.$router.push(ROUTER_MAIN)

      await HTTP.put('set_access?update', {
        id_access,
      })

      await this.getAccess()
      this.menus.showNotifies = false
    },
  },
}
</script>

<style scoped lang="sass">
.notify
  padding-top: 6px
  border-radius: 60px
  &__title
    span
      font-size: 14px
  &__datepoint
    span
      font-weight: 300
      font-size: 11px
  &__subject
    font-size: 12px
    font-weight: 500
    line-height: 16px
  &__mess
    font-weight: 300
    font-size: 12px

.v-menu__content
  box-shadow: none !important
</style>
