<template>
  <v-flex md4 sm12 xs12>
    <v-layout column>
      <dict-title :title="$t('info.title')"></dict-title>

      <v-layout class="pt-8" style="height: calc(100vh - 110px)">
        <v-flex>
          <v-card class="py-3 pt-10 mt-n4 elevation-0 px-3">
            <v-layout align-center justify-space-between class="info__title black--text">
              <p class="mb-0 flex-1">{{ 'Дата' }}</p>
              <date-picker
                v-model="date_range"
                class="black--text"
                type="date"
                range
                value-type="format"
                format="YYYY-MM-DD"
                placeholder="Выберите период"
                confirm
                confirm-text="Применить"
                range-separator=" - "
                :clearable="false"
                style="maxwidth: 200px"
              >
                <template v-slot:icon-calendar>
                  <v-icon v-text="'$calendar'" size="17"></v-icon>
                </template>
              </date-picker>
            </v-layout>

            <v-layout align-center justify-space-between class="info__title black--text">
              <p class="mb-0 text-no-wrap">{{ 'Количество договоров' }}</p>
              <span class="text-right">{{ contractInfoData?.ccount ?? 0 }}</span>
            </v-layout>
            <v-layout align-center justify-space-between class="info__title black--text">
              <p class="mb-0 text-no-wrap">{{ 'Сумма договоров, руб. без НДС' }}</p>
              <span class="text-right">{{
                formatFloatValue(contractInfoData?.price_full ?? 0)
              }}</span>
            </v-layout>
            <v-layout align-center justify-space-between class="info__title black--text">
              <p class="mb-0 text-no-wrap">{{ 'Выполнено, руб. без НДС' }}</p>
              <span class="text-right">{{
                formatFloatValue(contractInfoData?.price_done ?? 0)
              }}</span>
            </v-layout>
            <v-layout align-center justify-space-between class="info__title black--text">
              <p class="mb-0 text-no-wrap">{{ 'Оплачено, руб. без НДС' }}</p>
              <span class="text-right">{{
                formatFloatValue(contractInfoData?.price_paid ?? 0)
              }}</span>
            </v-layout>
            <v-layout align-center justify-space-between class="info__title black--text">
              <p class="mb-0 text-no-wrap">{{ 'К оплате по договору, руб. без НДС' }}</p>
              <span class="text-right">{{
                formatFloatValue(contractInfoData?.price_pay_con ?? 0)
              }}</span>
            </v-layout>
            <v-layout align-center justify-space-between class="info__title black--text">
              <p class="mb-0 text-no-wrap">{{ 'К оплате за исполненное, руб. без НДС' }}</p>
              <span class="text-right">{{
                formatFloatValue(contractInfoData?.price_fulfilled ?? 0)
              }}</span>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-flex>
</template>

<script>
import DatePicker from 'vue2-datepicker'

import Api from '@/api/api'
import dictTitle from '@/components/custom/dicts/title'
import {getDateRangeOfMonth} from '@/utils/date.js'
import {hasErrorResponse} from '@/utils/api.js'
import {convertNumberToLocaleString} from '@/utils/number'

export default {
  components: {
    dictTitle,
    DatePicker,
  },

  props: {
    component: {
      type: String,
      default: 'default',
    },
  },

  data: () => ({
    contractInfoData: null,
    date_range: [],
  }),

  mounted() {
    this.setDefaultDateRange()
  },
  watch: {
    $route() {
      this.setDefaultDateRange()
      this.getContractInfoData()
    },
    date_range() {
      this.saveDateRangeToSessionStorage()
      this.getContractInfoData()
    },
  },

  methods: {
    setDefaultDateRange() {
      const sessionDateRange = window.sessionStorage.getItem('dateRange')

      if (sessionDateRange) {
        this.date_range = sessionDateRange.split(',')
      } else {
        this.date_range = getDateRangeOfMonth()
      }
    },
    formatFloatValue(value) {
      return convertNumberToLocaleString(value)
    },
    saveDateRangeToSessionStorage() {
      window.sessionStorage.setItem('dateRange', this.date_range.toString())
    },
    async getContractInfoData() {
      const [date_from, date_to] = this.date_range
      const {data} = await Api.contracts.post.infoAll({
        date_from,
        date_to,
      })

      if (!hasErrorResponse(data)) {
        this.contractInfoData = data
      }
    },
  },
}
</script>
