<template>
  <v-app-bar app clipped-left dark class="main_color" dense height="40" :scroll-threshold="0">
    <v-layout align-center>
      <v-flex offset-lg1 offset-md0>
        <v-layout align-center>
          <v-flex hidden-md-and-down>
            <toolbar-navigation></toolbar-navigation>
          </v-flex>

          <v-flex hidden-lg-and-up>
            <v-menu color="transparent" nudge-right="30">
              <template v-slot:activator="{on}">
                <v-btn v-on="on" small icon>
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-card>
                <toolbar-navigation class="d-flex flex-column py-2"></toolbar-navigation>
              </v-card>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex v-if="auth.type_module === 'smis'">
        <v-layout v-if="/monitoring/.test($route.path)">
          <v-layout justify-center class="py-2 px-1 monitoring__info">
            <p>Систем</p>
            <span>{{ monitoring.amount.systems }}</span>
          </v-layout>
          <v-layout justify-center class="py-2 px-1 monitoring__info">
            <p>Инцидентов</p>
            <span>{{ monitoring.amount.accidents }}</span>
          </v-layout>
          <v-layout justify-center class="py-2 px-1 monitoring__info">
            <p>Аварий</p>
            <span>{{ monitoring.amount.failures }}</span>
          </v-layout>
        </v-layout>
      </v-flex>

      <v-flex>
        <v-layout justify-end align-center>
          <v-flex class="posr">
            <v-layout justify-end align-center class="overflow-hidden">
              <h4 class="hidden-sm-and-down mr-2">
                {{ auth.name }}
              </h4>
              <toolbar-notifies></toolbar-notifies>
            </v-layout>
          </v-flex>

          <v-menu
            transition="slide-y-transition"
            color="transparent"
            :nudge-top="-35"
            :nudge-right="-300"
            :close-on-content-click="false"
            v-model="menus.companies"
          >
            <template v-slot:activator="{on}">
              <v-btn v-on="on" small icon text max-height="32" max-width="32" class="my-0 ml-0">
                <v-icon>mdi-account-circle</v-icon>
              </v-btn>
            </template>
            <v-card style="min-width: 320px; max-width: 450px">
              <v-layout column>
                <v-layout justify-center column>
                  <v-layout class="py-2 px-3">
                    <v-flex xs4>
                      <v-layout justify-center align-center class="mt-2">
                        <v-icon
                          v-if="auth.type_module === 'smis'"
                          v-text="getIconOfColorTheme('acs', auth.jdata.color)"
                          style="height: 76px; width: 76px"
                        ></v-icon>
                        <v-icon
                          v-else-if="auth.type_module === 'skifme'"
                          v-text="getIconOfColorTheme('eco', auth.jdata.color)"
                          style="height: 76px; width: 76px"
                        ></v-icon>
                        <v-icon
                          v-else
                          v-text="getIconOfColorTheme(auth.type_module, auth.jdata.color)"
                          style="height: 76px; width: 76px"
                        ></v-icon>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 offset-xs1 class="py-2">
                      <v-layout column>
                        <p style="font-size: 13px; margin-bottom: 0px">
                          {{ auth.name }}
                        </p>
                        <p style="font-size: 13px; color: #676666; margin-bottom: 9px">
                          {{ auth.type_module }}
                        </p>
                        <v-btn
                          small
                          to="/cabinet"
                          @click="menus.companies = false"
                          depressed
                          class="btn__company btn__save text-none mx-0 my-0"
                        >
                          {{ $t('component.cabinet') }}
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                  <v-layout column class="overflow-hidden" style="max-height: 250px">
                    <v-layout column class="overflow-y-auto">
                      <v-card
                        v-for="company in getOtherCompanies"
                        :key="company.id"
                        @mouseenter="$set(company, 'cardHover', true)"
                        @mouseleave="$set(company, 'cardHover', false)"
                        :class="company.cardHover ? 'card__company--blue' : 'card__company--gray'"
                        elevation="0"
                        v-ripple
                      >
                        <v-layout column @click="setAccess(company)">
                          <v-layout align-center class="py-1 px-3">
                            <v-flex xs4>
                              <v-layout justify-center class="">
                                <!-- <v-icon
                                  v-text="
                                    getIconOfColorTheme(company.type_module, company.jdata.color)
                                  "
                                  style="height: 44px; width: 44px"
                                ></v-icon> -->
                                <v-icon
                                  v-if="company.type_module === 'smis'"
                                  v-text="getIconOfColorTheme('acs', company.jdata.color)"
                                  style="height: 44px; width: 44px"
                                ></v-icon>
                                <v-icon
                                  v-else-if="company.type_module === 'skifme'"
                                  v-text="getIconOfColorTheme('eco', company.jdata.color)"
                                  style="height: 44px; width: 44px"
                                ></v-icon>
                                <v-icon
                                  v-else
                                  v-text="
                                    getIconOfColorTheme(company.type_module, company.jdata.color)
                                  "
                                  style="height: 44px; width: 44px"
                                ></v-icon>
                              </v-layout>
                            </v-flex>
                            <v-flex xs5 offset-xs1 class="py-2">
                              <p style="font-size: 13px; margin-bottom: 0px">
                                {{ company.name }}
                              </p>
                              <p style="font-size: 13px; color: #676666; margin-bottom: 0px">
                                {{ company.type_module }}
                              </p>
                            </v-flex>
                            <v-flex xs3 class="py-2">
                              <v-layout justify-end>
                                <p class="mx-auto mb-1">
                                  {{ $t(`menu.${company.type_module}`) }}
                                </p>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-divider></v-divider>
                        </v-layout>
                      </v-card>
                    </v-layout>
                  </v-layout>
                </v-layout>
                <v-divider></v-divider>
                <v-layout class="py-3 px-3" style="background: #f0eeee" justify-center>
                  <v-btn
                    @click="logout()"
                    depressed
                    class="btn__company btn__save text-none mx-0 my-0 px-3"
                  >
                    {{ $t('login.logout') }}
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-card>
          </v-menu>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-app-bar>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import auth from '@/mixins/auth'
import HTTP from '@/api/http'
import keycloak from '@/utils/keycloak'
import {ROUTER_MAIN} from '@/utils/constants'

import toolbarNotifies from '@/components/custom/toolbar/notifies'
import toolbarNavigation from '@/components/custom/toolbar/navigation'

export default {
  components: {
    toolbarNotifies,
    toolbarNavigation,
  },

  mixins: [auth],

  data() {
    return {
      menus: {
        companies: false,
      },
    }
  },
  computed: {
    getOtherCompanies() {
      return this.getDicts('modules')
        .filter((company) => company.id !== this.auth.id)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    ...mapState(['monitoring']),
    ...mapGetters(['getThemeColor', 'getThemeColors', 'getDicts', 'auth']),
  },

  methods: {
    async setAccess(company) {
      this.$router.push(ROUTER_MAIN).catch(() => {})

      const token = company.api_key
      localStorage.setItem('token', token)

      this.menus.companies = false

      await this.getAccess()
    },
    async logout() {
      keycloak.logout()
    },
    getIconOfColorTheme(type_module, color) {
      const colors = this.getThemeColors.map(({color_name}) => color_name)
      if (colors.includes(color)) {
        return `$vuetify.icons.modules_${type_module}_${color}`
      }
      return `$vuetify.icons.modules_${type_module}_${this.getThemeColor}`
    },
  },
}
</script>
