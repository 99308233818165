import Vue from 'vue'
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vuetify/dist/vuetify.min.css'
import i18n from '@/main'
import values from '@/plugins/icons'
import ru from 'vuetify/lib/locale/ru'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        main_color: '#389667',
        report_color: '#E3F3EC',
        blue_custom: '#4B6CFB',
        grey_custom: '#495057',
        grey_darken_custom: '#83888D',
        blue_grey_custom: '#4B6CFB',
        white: '#FFFFFF',
        red_color: '#D94759',
        tax_out_color: '#A6A4A4',
        tax_alert_color: '#BF4426',
        tax_red_color: '#FFE6E5',
        tax_green_color: '#DEF5E3',
        tax_blue_color: '#DDEBFC',
      },
    },
  },
  lang: {
    locales: {ru},
    current: 'ru',
    t: (prop, ...params) => i18n.t(prop, params),
  },
  icons: {
    iconfont: 'mdi',
    values,
  },
})
