import dayjs from 'dayjs'

export const getDateRangeOfDay = (date, format = 'YYYY-MM-DD') => {
  return [dayjs(date).format(format), dayjs(date).add(1, 'days').format(format)]
}

export const getDateRangeOfMonth = (date, format = 'YYYY-MM-DD') => {
  return [dayjs(date).format(format), dayjs(date).add(1, 'months').format(format)]
}

export const getToday = (format = 'YYYY-MM-DD') => {
  return dayjs().format(format)
}
