import HTTP from '@/api/http'

const tables = {
  post: (request, params) => HTTP.post(request, params),
}

const dicts = {
  post: (request, params) => HTTP.post(request, params),
}

const contracts = {
  post: {
    info: (params) => HTTP.post('contracts_transactions', params),
    infoAll: (params) => HTTP.post('contracts_transactions_all', params),
  },
}

const counterparties = {
  post: {
    info: (params) => HTTP.post('counterparties_transactions', params),
    geometryList: (params) => HTTP.post('geometrys_list', params),
  },
}

export default {
  tables,
  dicts,
  contracts,
  counterparties,
}
