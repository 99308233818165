<template>
  <options-settings
    component="transactions"
    :options="options"
    :configFields="getContractsTransactionConfigFields"
    style="overflow-y: auto"
  >
    <v-layout wrap justify-center class="mt-2">
      <options-button :button="button" @click="createTransaction()" :disabled="isDisabled">
      </options-button>
    </v-layout>
  </options-settings>
</template>

<script>
import {mapGetters} from 'vuex'

import setListsOfElems from '@/mixins/setListsOfElems'

import optionsButton from '@/components/custom/options/button'
import optionsSettings from '@/components/custom/options/settings'

export default {
  components: {
    optionsButton,
    optionsSettings,
  },

  mixins: [setListsOfElems],

  props: {
    component: {
      type: String,
      default: '',
    },
    contract: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      options: {
        jdata: {},
      },
      button: {
        label: 'Создать акт',
        field: `cmd_save?component=${this.component}`,
      },
      fields: ['date_oper', 'type_transaction', 'summ', 'type2_transaction', 'id_account'],
    }
  },
  computed: {
    getContractsTransactionConfigFields() {
      return (this.config?.fields?.transactions ?? []).filter((item) =>
        this.fields.includes(item.field)
      )
    },
    isDisabled() {
      const typeTransactionValue = 'price_paid'

      if (this.options.type_transaction === typeTransactionValue) {
        return this.fields.some((field) => !this.options[field])
      }

      return this.fields
        .filter((field) => field !== 'type2_transaction')
        .some((field) => !this.options[field])
    },
    ...mapGetters(['config', 'getDicts', 'getOptions']),
  },

  mounted() {
    this.setTypeTransaction()
  },

  methods: {
    async createTransaction() {
      const transaction = await this.createItemOfDictionary(this.button, {
        id_contract: this.contract.id,
      })
      this.updateItemOfDictionary(transaction)
      this.resetFields()
      this.setTypeTransaction()
    },

    setTypeTransaction() {
      const field = 'type_transaction'
      const typeTransactionField = this.getContractsTransactionConfigFields.find(
        (item) => item.field === field
      )

      this.$set(this.options, field, typeTransactionField.items[0].key)
    },
  },
}
</script>
