<template>
  <div class="py-3 px-2">
    <v-card class="mx-3 mt-2 pt-2 pb-3">
      <v-flex>
        <template v-if="component === 'contracts'">
          <InfoGroupContracts :component="component" :options="options" />
        </template>

        <template v-else-if="component === 'counterparties'">
          <InfoGroupCounterparties :component="component" :options="options" />
        </template>

        <v-layout v-else wrap>
          <template v-for="(field, index) in config.info[component]">
            <template v-if="/^id_/.test(field.key)">
              <v-flex md3 sm6 xs12 v-if="options[field.key]" :key="index">
                <v-layout column align-center class="options__title">
                  <p class="mb-0">{{ field.label }}</p>
                </v-layout>
                <v-layout justify-center>
                  <v-flex xs10>
                    <v-layout justify-center>
                      <v-btn
                        text
                        class="monitoring__btn"
                        :to="`/dictionary/${getDictName(field.key)}s/${getDictName(field.key)}_${
                          options[field.key]
                        }`"
                      >
                        <span class="monitoring__btn--label">{{
                          getLinkTitle(options[field.key], `${getDictName(field.key)}s`)
                        }}</span>
                      </v-btn>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </template>

            <template v-if="!/^id_/.test(field.key)">
              <v-flex md3 sm6 xs12 :key="index">
                <v-layout column align-center class="options__title black--text">
                  <p class="mb-0">{{ field.label }}</p>
                  <span class="text-center">{{ getModelValue(field) }}</span>
                </v-layout>
              </v-flex>
            </template>
          </template>
        </v-layout>
      </v-flex>
    </v-card>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import InfoGroupContracts from './InfoGroup/InfoGroupContracts.vue'
import InfoGroupCounterparties from './InfoGroup/InfoGroupCounterparties.vue'

export default {
  components: {
    InfoGroupContracts,
    InfoGroupCounterparties,
  },

  props: {
    component: {
      type: String,
    },
    options: {
      type: Object,
      default: () => ({
        jdata: {},
      }),
    },
  },

  computed: {
    getModelValue() {
      return (field) => {
        const findKey = this.config.info[this.component].find((item) => item.key === field.key)
        const findTag = this.config.fields[this.component].find(
          (column) => column.field === findKey.key
        )
        if (findTag && findTag.tag === 'select-list') {
          const {items} = this.config.fields[this.component].find(
            (item) => item.field === field.key
          )
          const findItem = items.find(
            (item) =>
              item.key === this.options[field.key] || item.key === this.options.jdata[field.key]
          )
          if (findItem) {
            return findItem.label || this.$t('false')
          }
        }
        return this.options[field.key] || this.options.jdata[field.key]
      }
    },
    getDictName() {
      return (prop) => prop.replace('id_', '')
    },
    ...mapState(['auth', 'user_data']),
    ...mapGetters(['config', 'getDicts']),
  },

  methods: {
    getLinkTitle(id, component) {
      const findElem = this.getDicts(component).find((elem) => elem.id === id)
      if (findElem) {
        const infoItem = this.config.info[component]?.find((item) => item.place === 'ifield1')
        const {key} = infoItem
        return findElem[key] ?? ''
      }
      return ''
    },
  },
}
</script>
