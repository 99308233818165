<template>
  <v-flex xs12 d-flex>
    <v-flex xs6>
      <v-layout column>
        <template v-if="fields.top_left">
          <p class="mb-0">{{ fields.top_left.label }}</p>
          <span>{{ getModelValue('top_left') }}</span>
        </template>
        <template v-if="fields.bottom_left">
          <p class="mb-0">{{ fields.bottom_left.label }}</p>
          <span>{{ getModelValue('bottom_left') }}</span>
        </template>
      </v-layout>
    </v-flex>
    <v-flex xs6 class="card__dict--weight">
      <v-layout column hidden-md-and-down>
        <template v-if="fields.top_right">
          <p class="mb-0">{{ fields.top_right.label }}</p>
          <span>{{ getModelValue('top_right') }}</span>
        </template>
        <template v-if="fields.bottom_right">
          <p class="mb-0">{{ fields.bottom_right.label }}</p>
          <span>{{ getModelValue('bottom_right') }}</span>
        </template>
      </v-layout>
    </v-flex>
  </v-flex>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    component: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      fields: {},
    }
  },
  computed: {
    getModelValue() {
      return (field) => {
        const {key: property} = this.fields[field]

        const {tag, item_text} =
          this.config.fields[this.component].find(
            (column) => column.field === this.fields[field].key
          ) || {}
        if (tag === 'select-list') {
          return this.getValueByTag(property)
        }

        const propNameWithId = (prop) => /^id_/.test(prop)
        if (propNameWithId(property)) {
          return this.getValueById(property, item_text)
        }

        return this.getDefaultValue(property)
      }
    },
    ...mapGetters(['config', 'getDicts']),
  },

  created() {
    this.fields = this.config.entries[this.component].reduce(
      (total, item) => ({
        ...total,
        [item.place]: item,
      }),
      {}
    )
  },

  methods: {
    getDefaultValue(property) {
      const isBooleanValue = (value) => !!value === value
      const getPropertyValue = (prop) => this.options[prop] || this.options.jdata[prop]
      const value = getPropertyValue(property)

      if (isBooleanValue(value)) {
        return this.$t(`${value}`)
      }

      return value
    },

    getValueById(property, key) {
      const dict = `${property.replace('id_', '')}s`
      const element = this.getDicts(dict).find(({id}) => id === this.options[property]) || {}
      return element[key]
    },

    getValueByTag(property) {
      const {items = []} =
        this.config.fields[this.component].find((item) => item.field === property) || {}
      const matchLabel = ({key}) =>
        key === this.options[property] || key === this.options.jdata[property]
      const {label} = items.find(matchLabel) || {}

      return label || this.$t('false')
    },
  },
}
</script>
