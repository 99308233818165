import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters(['config']),
  },

  methods: {
    getRequestOptions({tagName, date_from = null, date_to = null}) {
      const {items = {}, field: request} = this.config.fields[this.component].find(
        ({tag}) => tag === tagName
      )
      const id = `id_${this.component.slice(0, -1)}`

      return {request, date_from, date_to, [id]: this.$route.params[this.component], ...items}
    },
  },
}
