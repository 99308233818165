var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{attrs:{"md3":"","sm12":"","xs12":""}},[_c('dict-title',{attrs:{"title":`${_vm.config.module[_vm.component].label} - ${_vm.getDicts(_vm.component).length}`}},[_c('v-layout',{attrs:{"justify-end":"","fill-height":""}},[(/dict/.test(_vm.$route.path))?_c('v-layout',{staticClass:"mr-1",attrs:{"justify-end":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('v-btn',_vm._g({staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","disabled":!_vm.$route.params[_vm.component],"to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                _vm.$route.params[_vm.component]
              }`,"exact":""},on:{"click":function($event){return _vm.setNavigation('')}}},on),[_c('v-icon',{attrs:{"size":"19"}},[_vm._v("$build")])],1)]}}],null,false,1389693038)},[_c('span',[_vm._v(_vm._s(_vm.$t('main.forms')))])]),_vm._l((_vm.getTopBtns),function(item){return _c('v-tooltip',{key:item.tag,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [(item.tag === 'top-btn-debug')?[_c('v-btn',_vm._g({key:item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                  _vm.$route.params[_vm.component]
                }/debug`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation('debug')}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$debug")])],1)]:(item.tag === 'top-btn-counter')?[_c('v-btn',_vm._g({key:item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                  _vm.$route.params[_vm.component]
                }/consumption?field=${item.field}`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(`consumption?field=${item.field}`)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$dicts_reports_report")])],1)]:(item.tag === 'top-btn-reports')?[_c('v-btn',_vm._g({key:item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","exact":"","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                  _vm.$route.params[_vm.component]
                }/reports?field=${item.field.replace('#', '_')}`,"disabled":item.disabled},on:{"click":function($event){_vm.setNavigation(`reports?field=${item.field.replace('#', '_')}`)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$dicts_reports_report")])],1)]:(item.tag === 'top-btn-tables')?[_c('v-btn',_vm._g({key:item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","exact":"","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                  _vm.$route.params[_vm.component]
                }/tables?field=${item.field}`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(`tables?field=${item.field}`)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$dicts_reports_report")])],1)]:(item.tag === 'top-btn-gantt')?[_c('v-btn',_vm._g({key:item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","exact":"","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                  _vm.$route.params[_vm.component]
                }/gantt`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(`gantt`)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-waves")])],1)]:(item.tag === 'top-btn-calendar')?[_c('v-btn',_vm._g({key:item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","exact":"","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                  _vm.$route.params[_vm.component]
                }/calendar`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(`calendar`)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-calendar")])],1)]:(item.tag === 'top-btn-history')?[_c('v-btn',_vm._g({key:item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                  _vm.$route.params[_vm.component]
                }/history?request=${item.field}`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(`history/?request=${item.field}`)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$history")])],1)]:(item.tag === 'top-button')?[_c('v-btn',_vm._g({key:item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","to":item.url,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(item.field)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(`$${item.field}`))])],1)]:_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.label))])])})],2):_vm._e()],1)],1),_c('div',{staticClass:"d-flex overflow-hidden fill-height pt-4"},[_c('v-card',{staticClass:"d-flex flex-column elevation-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"px-3 mt-2"},[_c('v-text-field',{attrs:{"type":"search","color":"main_color"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"mt-2",attrs:{"size":"14"}},[_vm._v("$search")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{ref:"dictList",staticClass:"overflow-y-auto dict__body flex-grow-1"},_vm._l((_vm.postItems),function(item){return _c('v-card',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],key:item.id,ref:item.id,refInFor:true,staticClass:"card__dict",style:(_vm.getStatusColor(item)),attrs:{"color":_vm.getCardColor(item),"active-class":"transparent"},on:{"click":function($event){_vm.$router.push(_vm.getRoute(item)).catch((err) => {})}}},[_c('dict-info',{attrs:{"component":_vm.component,"options":item}})],1)}),1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }