const mutations = {
  SET_ACTIVE_MODULE: (state, payload) => {
    state.activeModule = payload
  },
}

export default {
  namespaced: true,
  state: {
    activeModule: null,
  },
  mutations,
}
