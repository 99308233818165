<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="$t('gantt.title')"></dict-title>

    <div class="d-flex overflow-hidden pt-4 fill-height">
      <v-card class="d-flex flex-column elevation-2 pt-4 flex-grow-1">
        <v-layout class="pt-6 px-3 flex-grow-0 overflow-x-auto" style="max-width: 100%">
          <frappe-gantt v-if="tasks.length" :tasks="tasks"></frappe-gantt>
        </v-layout>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import frappeGantt from '@/components/frappeGantt/frappeGantt.vue'
import dictTitle from '@/components/custom/dicts/title'
import {mapGetters} from 'vuex'
import HTTP from '@/api/http'
import moment from 'moment'
import reportsRequests from '@/mixins/reportsRequests'

export default {
  components: {
    frappeGantt,
    dictTitle,
  },

  mixins: [reportsRequests],

  props: {
    component: {
      type: String,
    },
  },

  data: () => ({
    chartStart: null,
    chartEnd: null,
    tasks: [],
  }),

  computed: {
    ...mapGetters(['getDicts', 'config']),
  },

  beforeMount() {
    this.getGanttsData()
  },

  watch: {
    $route() {
      this.getGanttsData()
    },
  },

  methods: {
    async getGanttsData() {
      const {request, ...options} = this.getRequestOptions({
        tagName: 'top-btn-gantt',
        date_from: this.chartStart,
        date_to: this.chartEnd,
      })
      const {data} = await HTTP.post(request, options)

      if (data.models) {
        let minDate = null
        let maxDate = null

        const updateMinMaxDates = (from, to) => {
          if (minDate === null || from < minDate) {
            minDate = from
          }

          if (maxDate === null || to > maxDate) {
            maxDate = to
          }
        }

        const rowList = data.models
          .sort((itemA, itemB) => (itemA.date_from > itemB.date_from ? 1 : -1))
          .map((item, index) => {
            const barItem = {
              id: index,
              start: item.date_from,
              end: item.date_to,
              name: item.name,
              progress: 100,
              dependencies: null,
            }

            updateMinMaxDates(item.date_from, item.date_to)

            return barItem
          })

        this.chartStart = minDate
        this.chartEnd = maxDate
        this.tasks = rowList
      }
    },
  },
}
</script>
