<template>
  <div class="globalSelectDictElement" align-center>
    <v-autocomplete
      class="globalSelectDictElement_select input__main"
      clearable
      :items="getDicts(dict)"
      :item-text="text"
      :item-value="value"
      dense
      hide-details
      @input="$emit('select', $event)"
      background-color="white"
      color="main_color"
      item-color="main_color"
      light
      :label="label"
    ></v-autocomplete>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    dict: {
      type: String,
    },
    label: {
      type: String,
    },
    text: {
      type: String,
    },
    value: {
      type: String,
    },
  },

  computed: {
    ...mapGetters(['getDicts']),
  },
}
</script>
