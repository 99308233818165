<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="getTitleOptions">
      <options-tooltip-json :json="tooltip_json"></options-tooltip-json>
    </dict-title>

    <div class="d-flex overflow-hidden fill-height pt-4">
      <v-card
        class="d-flex flex-column pt-3"
        style="width: 100%"
        v-touch="{
          right: () => {
            $router.push(`/dictionary/${component}`)
          },
        }"
      >
        <options-info class="flex-grow-0" :component="component" :options="options"></options-info>

        <v-layout class="d-flex overflow-y-hidden">
          <v-tabs
            v-model="tabs.model"
            v-if="tabs.active"
            background-color="#F0EEEE"
            color="main_color"
            style="width: 100%"
            class="d-flex flex-column overflow-hidden"
          >
            <v-tabs-slider color="main_color"></v-tabs-slider>
            <v-tab v-for="tab in getTabs" :key="tab.field" style="font-size: 11px" v-ripple="false">
              {{ tab.label || tab.field }}
            </v-tab>
            <v-tabs-items v-model="tabs.model" class="mt-1 flex-grow-1 overflow-y-auto">
              <v-tab-item
                v-for="(item, i) in getTabs"
                :key="item.field"
                :transition="false"
                :reverse-transition="false"
              >
                <v-card flat class="d-flex">
                  <template v-if="item.tag === 'tabs-settings'">
                    <v-col md="8">
                      <options-settings v-bind="{...item.items, options}" style="overflow-y: auto">
                        <v-layout wrap justify-center class="mt-2">
                          <template v-for="button in getActionBtns">
                            <options-button
                              v-if="shouldShowComponent(button)"
                              :key="button.field"
                              :button="button"
                              @click="saveChangesOfItem(button)"
                            >
                            </options-button>
                          </template>
                        </v-layout>
                      </options-settings>
                    </v-col>
                    <v-col md="4" v-if="component === 'contracts'">
                      <options-settings-contracts component="transactions" :contract="options" />
                    </v-col>
                  </template>

                  <template v-if="item.tag === 'tabs-list'">
                    <options-list v-bind="item.items"></options-list>
                  </template>

                  <template v-if="item.tag === 'tabs-formula'">
                    <options-formula v-bind="{...item.items, options}"></options-formula>
                  </template>

                  <template v-if="item.tag === 'tabs-simcir'">
                    <options-simcir v-bind="item.items"></options-simcir>
                  </template>

                  <template v-if="item.tag === 'tabs-table'">
                    <options-table v-bind="item.items"></options-table>
                  </template>

                  <template v-if="item.tag === 'tabs-cam'">
                    <options-cam v-bind="{...item.items, options}"></options-cam>
                  </template>

                  <template v-if="item.tag === 'tabs-train'">
                    <options-train v-bind="{...item.items, options}"></options-train>
                  </template>

                  <template v-if="item.tag === 'tabs-commands'">
                    <options-commands></options-commands>
                  </template>

                  <template v-if="item.tag === 'tabs-leafletMap'">
                    <options-leaflet-map
                      v-if="tabs.model === i"
                      :options.sync="options"
                      v-bind="item.items"
                    >
                    </options-leaflet-map>
                  </template>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-layout>
      </v-card>
    </div>

    <v-snackbar top right absolute v-model="notify.show" :timeout="2000">
      <v-layout column class="px-2">
        <h2 class="mb-3 mx-auto white--text">{{ notify.message }}</h2>
        <v-flex>
          <v-layout justify-center>
            <v-btn
              color="main_color"
              text
              depressed
              @click="notify.show = false"
              class="mx-0 my-0 px-2"
            >
              <span>{{ $t('close') }}</span>
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-snackbar>
  </v-flex>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import HTTP from '@/api/http'

import setListsOfElems from '@/mixins/setListsOfElems'

import optionsInfo from '@/components/custom/options/info'
import optionsList from '@/components/custom/options/list'
import optionsLeafletMap from '@/components/custom/options/leafletMap'
import optionsTable from '@/components/custom/options/table'
import optionsSimcir from '@/components/custom/options/simcir'
import optionsFormula from '@/components/custom/options/formula'
import optionsTooltipJson from '@/components/custom/options/tooltipJson'
import optionsCam from '@/components/custom/options/cam'
import optionsTrain from '@/components/custom/options/train'
import optionsCommands from '@/components/custom/options/commands'
import optionsButton from '@/components/custom/options/button'
import optionsSettings from '@/components/custom/options/settings'
import optionsSettingsContracts from '@/components/custom/options/settingsContracts/settingsContracts'
import dictTitle from '@/components/custom/dicts/title'

export default {
  components: {
    optionsInfo,
    optionsList,
    optionsLeafletMap,
    optionsTable,
    optionsSimcir,
    optionsFormula,
    optionsTooltipJson,
    optionsCam,
    optionsTrain,
    optionsCommands,
    optionsButton,
    optionsSettings,
    dictTitle,
    optionsSettingsContracts,
  },

  mixins: [setListsOfElems],

  props: {
    component: {
      type: String,
      default: '',
    },
    secondComponent: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      tabs: {
        items: [],
        active: false,
        model: null,
      },
      options: {
        jdata: {},
      },
      tooltip_json: {},
    }
  },
  computed: {
    getElemOptions() {
      const findElem = this.getDicts(this.component).find(
        (item) => item.id === this.$route.params[this.component]
      ) || {jdata: {}}
      return findElem
    },
    getTitleOptions() {
      const {key} =
        this.config.info[this.component].find((field) => field.place === 'ifield1') || {}

      const {tag} = this.config.fields[this.component].find((column) => column.field === key) || {}
      if (tag === 'select-list') {
        const {items} = this.config.fields[this.component].find((item) => item.field === key)
        const findItem = items.find(
          (item) => item.key === this.options[key] || item.key === this.options.jdata[key]
        )
        if (findItem) {
          return findItem.label || this.$t('false')
        }
      }

      const title = this.$t(this?.options?.[key]) || this.$t(this?.options?.jdata?.[key])

      return title
    },
    getActionBtns() {
      return this.config.fields[this.component]
        .filter((item) => item.tag === 'button')
        .sort((a, b) => (a.sort > b.sort ? 1 : -1))
    },
    getTabs() {
      const {[this.component]: inputs} = this.config.fields
      const tabs = inputs
        .filter((input) => input.tag.startsWith('tabs') && this.shouldShowComponent(input))
        .map((tab) => {
          return {
            ...tab,
            items: {
              ...tab.items,
              component: this.component,
              secondComponent: tab.field,
            },
          }
        })
        .sort((tabA, tabB) => (tabA.sort > tabB.sort ? 1 : -1))

      return tabs
    },
    ...mapState(['auth', 'user_data']),
    ...mapGetters(['config', 'getDicts', 'getOptions']),
  },

  watch: {
    $route(to, from) {
      this.updateInfoOfElem(to, from)
    },
  },
  created() {
    this.init(this.$route)
  },

  methods: {
    init(to) {
      this.getInfoOfElem()
      this.setElems()

      this.tabs.active = true
    },
    getInfoOfElem() {
      const findElem = this.getDicts(this.component).find(
        (item) => item.id === this.$route.params[this.component]
      )

      if (!findElem) {
        this.$router.push(`/dictionary/${this.component}`)
      }

      this.tooltip_json = findElem
      this.options = {...findElem}
    },
    updateInfoOfElem(to, from) {
      const fromPath = new RegExp(from.path.match(/dictionary\/\w+\/\w+_[\w+|-]+/))

      if (from.path.split('/')[2] !== to.path.split('/')[2]) {
        this.tabs.active = false
        this.tabs.model = 0
      }

      if (!fromPath.test(to.path)) {
        this.init(to)
      }
    },
  },
}
</script>

<style scoped lang="sass">
.input__multi-select ::v-deep
  .v-input__prepend-inner
    margin-top: 0px !important
  .v-input__append-inner
    margin-top: 1px !important
  svg
    height: 10px
    width: 10px
  input
    padding: 0
  .v-input
  .v-select__selection--comma
    margin: 3.5px 4px !important
  .v-select__slot
    // background: green
  .v-input__slot
    padding: 0 4px
    border: 1px solid rgba(73, 80, 87, 0.5)
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25)
    border-radius: 5px
    &:before
      border-style: none !important
      bottom: auto !important
  font-size: 12px
  margin: 0 0 15px 0
  padding: 0

::v-deep
  .v-list-item
    min-height: 24px
  .v-list-item__action
    margin: 0 32px 0 0
  .v-list-item__content
    padding: 0
  .v-list-item__title
    font-size: 12px
</style>
