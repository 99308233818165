<template>
  <v-layout>
    <v-dialog v-model="isShowEditDialog" width="480">
      <template v-slot:activator="{on}">
        <v-btn color="primary" depressed small icon dark v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <add-table-element-card
        :title="$t('edit')"
        :component="dictionaryModule.activeModule.items.type_rtable"
        :initOptions="props.item"
        config-list="fields"
        @save="updateTableElement"
      ></add-table-element-card>
    </v-dialog>

    <v-dialog v-model="isShowDeleteDialog" width="480">
      <template v-slot:activator="{on}">
        <v-btn color="error" depressed small icon dark v-on="on">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">{{ $t('remove') }}?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn__save px-4" text @click="deleteTableElement">
            {{ $t('remove') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import addTableElementCard from '@/components/tables/addTableElementCard'

export default {
  components: {
    addTableElementCard,
  },

  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    isShowEditDialog: false,
    isShowDeleteDialog: false,
  }),

  computed: {
    ...mapState(['dictionaryModule']),
    ...mapGetters(['getDicts']),
  },

  methods: {
    updateTableElement(element) {
      this.$emit('update', {...this.props.item, ...element})
      this.isShowEditDialog = false
    },
    deleteTableElement() {
      this.$emit('delete', this.props.item)
      this.isShowDeleteDialog = false
    },
  },
}
</script>
